import './font-faces.css';
import { importSVGs } from '@powdr/utils';

import { config } from './config';
import { fontTheme } from './fonts';
import { overrides } from './overrides';
import {
  defaultTheme, wwDefaultTheme, StaticTheme,
} from './themes';

const theme = {
  svgs: { ...importSVGs(require.context('../assets/svgs', true, /\.svg$/)) },
  defaultTheme,
  wwDefaultTheme,
  StaticTheme,
  config,
  ...fontTheme,
  overrides: { ...overrides },
};

export default theme;
