import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies, ComponentGroups, HeaderTransformOptions,
} from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG5: '10px',
  REG10: '12px',
  REG20: '14px',
  REG30: '16px',
  REG40: '20px',
  REG50: '22px',
  MED10: '24px',
  MED20: '26px',
  MED30: '28px',
  MED40: '30px',
  MED50: '32px',
  LRG10: '34px',
  LRG12: '36px',
  LRG20: '40px',
  LRG30: '45px',
  LRG40: '50px',
  LRG50: '55px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {
  [ComponentGroups.HERO]: {
    title: '140px',
    'title-large-bp': '140px',
    'title-smaller-bp': '75px',
    copy: '26px',
    'copy-larger-bp': '30px',
    'copy-large-bp': '28px',
    'copy-smaller-bp': '22px',
  },
  [ComponentGroups.WYSIWIG]: {
    'content-large-breakpoint': '20px',
    content: '16px',
    button: '16px',
  },
};

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "HelveticaNeue-Bold";',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "HelveticaNeue-Bold";',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "HelveticaNeue-Bold";',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "HelveticaNeue-Roman";',
};

const HeaderTransform = HeaderTransformOptions.NO_TRANSFORM;

export const fontTheme = {
  fonts,
  FontSize,
  HeaderTransform,
  ComponentFontAdjustments,
};
